<script>
import axios from "axios";
import { authHeader } from "../../../../helpers/auth-header";
import Badge from '@/components/buttons/tenancy-status'
import DetailsContent from './details-content-table.vue'
import DetailsKyc from "./details-content-kyc";
import InputModal from "@/components/modals/input-modal";
import UploadBtn from '@/components/buttons/uploadimage-button';
import LoadingSpinner from '@/components/Loading';
import { mapActions } from 'vuex';
export default {
	components: {
		Badge,
		DetailsContent,
		DetailsKyc,
		InputModal,
		UploadBtn,
		LoadingSpinner
	},
	data() {
		return {
			current_status: "",
			creator_data: [],
			tenancy_data: {},
			price_data: [],
			order_totals: [],
			upload_action: '',
			upload_error: '',
			update_status: '',
			update_status_error: '',
			badgeKey: 0,
			errors: [],
			isLoading: false
		}
	},
	async created() {
		await this.GetDetails()
	},
	props: [],
	mounted() {

	},
	methods: {
		...mapActions('modal', ['openConfirmModal', 'closeConfirmModal', 'openMessageModal']),
		async GetDetails() {
			this.isLoading = true;
			const result = await axios.get(`${process.env.VUE_APP_APIURL}/tenancy/${this.$route.params.id}`, {
				headers: authHeader(),
			}).catch(function (error) {
				alert("Something went wrong." + error.message);
				this.isLoading = false;
			});

			if (result.data.code === 'success') {
				const { data } = result.data;
				let tenancy = { ...data.tenancy };
				let member = { ...data.member };
				tenancy.fee_sharing = tenancy.fee_sharing != null ? tenancy.fee_sharing.charAt(0).toUpperCase() + tenancy.fee_sharing.slice(1) : null;
				tenancy.size = tenancy.size_full == null ? null : tenancy.size_full;
				tenancy.state_id = tenancy.state_id == null ? null : tenancy.state_name;
				tenancy.country_id = tenancy.country_id == null ? null : tenancy.country_name;
				if (tenancy.tenure != null) tenancy.tenure = parseInt(tenancy.tenure) > 1 ? tenancy.tenure += ' Years' : tenancy.tenure += ' Year';
				if (tenancy.rental != null) {
					const rental = JSON.parse(tenancy.rental);
					if (tenancy.rental_fee_structure == 1) {
						// tenancy.rental = rental.reduce((total, curr, index) => { return total += `Year ${index + 1} - RM ${curr},` }, "");
						tenancy.rental = rental.reduce((total, curr, index) => { 
							total += `Year ${index + 1} - RM ${curr}`;
							if (index < rental.length - 1) { 
								total += ', ';
							}
							return total;
						}, "");
					} else {
						tenancy.rental = 'RM ' + rental[0];
					}
				}

				tenancy.rental_fee_structure = parseInt(tenancy.rental_fee_structure) === 0 ? 'Same for all years' : 'Different for each years';

				if (parseInt(tenancy.renewable) == 1) {
					let month = tenancy.renewable_notice_month > 1 ? 'months' : 'month';
					tenancy.renewable = `Yes - ${tenancy.renewable_notice_month} ${month}`;
				}
				if (parseInt(tenancy.tenant_pre_terminate) == 1) {
					let month = tenancy.pre_termination_month > 1 ? 'months' : 'month';
					tenancy.tenant_pre_terminate = `Yes - ${tenancy.pre_termination_month} ${month}`;
				}

				if (tenancy.property_type == "intermediate_terrace_house" || tenancy.property_type == "shoplot") {
					tenancy.property_type_name += ` - ${tenancy.property_type_desc_name}`;
				}

				if (tenancy.title) {
					tenancy.title = this.htmlDecode(tenancy.title);
				}

				if (tenancy.tenant_address_line_1) {
					tenancy.tenant_address_line_1 = this.htmlDecode(tenancy.tenant_address_line_1);
				}

				if (tenancy.tenant_address_line_2) {
					tenancy.tenant_address_line_2 = this.htmlDecode(tenancy.tenant_address_line_2);
				}

				if (tenancy.landlord_address_line_1) {
					tenancy.landlord_address_line_1 = this.htmlDecode(tenancy.landlord_address_line_1);
				}

				if (tenancy.landlord_address_line_2) {
					tenancy.landlord_address_line_2 = this.htmlDecode(tenancy.landlord_address_line_2);
				}

				if (tenancy.address_line_1) {
					tenancy.address_line_1 = this.htmlDecode(tenancy.address_line_1);
				}

				if (tenancy.address_line_2) {
					tenancy.address_line_2 = this.htmlDecode(tenancy.address_line_2);
				}

				if (tenancy.landlord_company_address) {
					tenancy.landlord_company_address = this.htmlDecode(tenancy.landlord_company_address);
				}

				if (tenancy.tenant_company_address) {
					tenancy.tenant_company_address = this.htmlDecode(tenancy.tenant_company_address);
				}

				if (tenancy.property_amenity_remarks) {
					tenancy.property_amenity_remarks = this.htmlDecode(tenancy.property_amenity_remarks);
				}

				if (tenancy.property_remarks) {
					tenancy.property_remarks = this.htmlDecode(tenancy.property_remarks);
				}

				console.log('aa', data.order_totals)
				console.log('xzczxc', tenancy)

				if (data.order_totals.length) {
					const shippingItem = data.order_totals.find(item => item.code === 'shipping');
					const totalAmount = data.order_totals.find(item => item.code === 'total');
					const sstAmount = data.order_totals.find(item => item.code === 'sst');

					this.price_data = [
						{ "item": "Service Fee", "quantity": 1, "price": "RM" + tenancy.unit_price.toFixed(2) },
						{ "item": "Stamping Fee", "quantity": 1, "price": "RM" + tenancy.unit_stamping_fee.toFixed(2) },
					];

					if (shippingItem.value) {
						this.price_data.push({ "item": "Shipping Fee", "quantity": 1, "price": "RM" + shippingItem.value.toFixed(2) });
					}

					if (data.ctc != null) {
						this.price_data.push({ "item": "CTC Fee", "quantity": data.ctc.ctc_quantity, "price": "RM" + data.ctc.price.toFixed(2) });
					}

					this.price_data.push({ "item": "SST", "price": "RM" + sstAmount.value.toFixed(2) });

					this.price_data.push({ "item": "Total", "price": "RM" + totalAmount.value.toFixed(2) });

					this.order_totals = data.order_totals;
				}
				
				this.current_status = tenancy.status;
				this.tenancy_data = tenancy;
				this.creator_data = member;
				this.isLoading = false;
				console.log('aasdsads', this.creator_data)
				console.log('zzz', this.tenancy_data)
			} else if (result.data.code === 'permission_denied') {
				this.openMessageModal({ message: "Unable to view", redirect: '' });
				this.isLoading = false;
			} else {
				this.openMessageModal({ message: "Item not found", redirect: '' });
				this.isLoading = false;
			}
		},

		async uploadFile(e) {
			switch (this.upload_action) {
				case "amenity":
					await this.uploadAmenity(e);
					break;
				case 'tenant_individual_docs':
					await this.uploadkyc(e, 'ic', 'tenant');
					break;
				case 'tenant_business_docs':
					await this.uploadkyc(e, 'company_registration_cert', 'tenant');
					break;
				case 'tenant_signature':
					await this.uploadkyc(e, 'digital_signature', 'tenant');
					break;
				case 'landlord_individual_docs':
					await this.uploadkyc(e, 'ic', 'landlord');
					break;
				case 'landlord_business_docs':
					await this.uploadkyc(e, 'company_registration_cert', 'landlord');
					break;
				case 'landlord_signature':
					await this.uploadkyc(e, 'digital_signature', 'landlord');
					break;
				case 'stamp_doc':
					await this.uploadStampDocs(e);
					break;
			}
		},


		async uploadAmenity(e) {
			if (!e.has('file_upload[0]'))
				return;
			const result = await axios.post(`${process.env.VUE_APP_APIURL}/tenancy/upload-amenity/${this.tenancy_data.id}`, e, {
				headers: {
					'Content-Type': 'image/png',
					'Authorization': authHeader()["Authorization"]
				}
			}).catch(function (error) {
				alert("Fail to upload amenity images:" + error.message);
			});
			this.displayResult(result);
		},

		async uploadkyc(e, kyc_type, type) {
			e.append('kyc_type', kyc_type);
			e.append('type', type);
			e.append('order_id', this.$route.params.id);
			const result = await axios.post(`${process.env.VUE_APP_APIURL}/tenancy/upload-kyc`, e, {
				headers: {
					'Content-Type': 'image/png',
					'Authorization': authHeader()["Authorization"]
				}
			}).catch(function (error) {
				alert("Upload fail:" + error.message);
			});
			this.displayResult(result);
		},

		async uploadStampDocs(e) {
			const formData = new FormData();
			formData.append('file_upload', e.get('file_upload[0]'));
			const result = await axios.post(`${process.env.VUE_APP_APIURL}/tenancy/upload-stamp-doc/${this.tenancy_data.id}`, formData, {
				headers: {
					'Content-Type': 'image/png',
					'Authorization': authHeader()["Authorization"]
				}
			}).catch((error) => {
				if (error.response.data.code == 'invalid_field') {
					let errors = error.response.data.errors;
					this.errors = error.response.data.errors;
					let errMessage = Object.values(errors).reduce((accumulator, currentValue, i, arry) => {
						if (i == 8) {
							return accumulator + "..." + "\n";
						} else if (i > 8) {
							arry.splice(0);
							return accumulator;
						}
						return accumulator + currentValue + "\n";
					});
					this.openMessageModal({ message: "Some tenancy info is incomplete!\n" + errMessage, redirect: '' });
				}
			});

			const fd = new FormData()
			fd.append('id', this.tenancy_data.order_id)
			const updateResult = await axios.post(`${process.env.VUE_APP_PHP_API_URL}/order/agreement/update`, fd, {
				//
			}).catch(function (error) {
				alert("Something went wrong." + error.message);
			});

			if (updateResult.data.code === 'success') {
				const { data } = updateResult.data;
				console.log('data', data)
			}

			if (result != undefined) this.displayResult(result);
		},

		displayResult(result) {
			if (result.data.code === 'success') {
				this.openMessageModal({ message: "File upload success", redirect: 'refresh' });
			} else if (result.data.code === 'invalid_field') {
				if (this.upload_action != 'stamp_doc') {
					this.upload_error = result.data.errors["file_upload.0"];
				} else {
					this.upload_error = result.data.errors["file_upload"][0];
				}
			} else if (result.data.code === 'item_max_count') {
				this.upload_error = result.data.message;
			} else if (result.data.code === 'incomplete_property') {
				this.upload_error = result.data.message;
			} else if (result.data.code === 'permission_denied') {
				this.$refs.inputModal.closeModal();
				this.$refs.statusModel.closeModal();
				this.openMessageModal({ message: "Permission Denied", redirect: '' });
			} else {
				this.openMessageModal({ message: "Internal Errors", redirect: '' });
			}
		},

		async changeStatus() {
			this.errors = [];
			await axios.put(`${process.env.VUE_APP_APIURL}/tenancy/update-status/${this.$route.params.id}`, { "status": this.update_status }, {
				headers: authHeader(),
			}).then(e => {
				this.$refs.statusModel.closeModal();

				if (e.data.code == 'tenant_signature_needed') {
					this.openMessageModal({ message: "Please upload signature before changing status to 'Pending Landlord Signature'", redirect: '' });
				} else if (e.data.code == 'landlord_signature_needed') {
					this.openMessageModal({ message: "Please upload signature before changing status to 'Pending Tenant Signature'", redirect: '' });
				} else {
					this.openMessageModal({ message: "Status Updated", redirect: '' });
					this.current_status = e.data.data.tenancy.status;
				}
			}).catch((error) => {
				if (error.response.status == 422) {
					this.$refs.statusModel.closeModal();
					this.errors = error.response.data.errors;
					let errMessage = Object.values(this.errors).reduce((accumulator, currentValue, i, arry) => {
						if (i == 8) {
							return accumulator + "..." + "\n";
						} else if (i > 8) {
							arry.splice(0);
							return accumulator;
						}
						return accumulator + currentValue + "\n";
					});
					this.openMessageModal({ message: "Some tenancy info is incomplete!\n" + errMessage, redirect: '' });
				}
			});
		},

		editDetails() {
			if (this.current_status != 'draft' && this.current_status != 'payment_processing' && this.current_status != 'pending_payment' && this.current_status != 'pending_signature') {
				this.openMessageModal({ message: "Unable to edit after tenant or landlord has upload their signature.", redirect: '' });
			} else {
				this.$router.push({ name: "Tenancy Edit New", params: { id: this.$route.params.id } })
			}
		},

		async confirmPayment() {
			this.openConfirmModal({
				"message": "Is the payment for this tenancy has been verified?", "callback": async () => {
					const result = await axios.post(`${process.env.VUE_APP_APIURL}/tenancy/confirm-payment/${this.tenancy_data.id}`, {}, {
						headers: authHeader(),
					}).catch(function (error) {
						alert("Update fail:" + error.message);
					});
					if (result.data.code === 'success') {
						this.closeConfirmModal();
						this.current_status = result.data.data.status;
						this.openMessageModal({ message: "Updated", redirect: '' });
						// this.$emit('displayMessage', 'Payment is confirmed');
					} else if (result.data.code === 'invalid_status') {
						this.closeConfirmModal();
						// this.$emit('displayMessage', 'Invalid Status');
					} else {
						this.closeConfirmModal();
						// this.$emit('displayMessage', 'Internal Error');
					}
				}
			});
		},
		async downloadAgreement() {
			var order_agreement_id = this.tenancy_data.order_agreement_id ?? 0;
			console.log('OAI', order_agreement_id)
			if (order_agreement_id) {
				const result = await axios.get(`${process.env.VUE_APP_APIURL}/order-agreement/${order_agreement_id}`, {
					headers: authHeader(),
				}).catch(function (error) {
					alert("Something went wrong." + error.message);
				});

				if (result.data.code === 'success') {
					const { data } = result.data;
					const pdfUrl = data.order_agreement.agreement;

					window.open(pdfUrl, '_blank');
					console.log('Agreement URL:', data.order_agreement.agreement);
				} else if (result.data.code === 'file_not_yet_exist') {
					const fd = new FormData()
					fd.append('id', this.tenancy_data.order_id)
					const updateResult = await axios.post(`${process.env.VUE_APP_PHP_API_URL}/order/agreement/update`, fd, {
						//
					}).catch(function (error) {
						alert("Something went wrong." + error.message);
					});

					if (updateResult.data.code === 'success') {
						const result = await axios.get(`${process.env.VUE_APP_APIURL}/order-agreement/${order_agreement_id}`, {
							headers: authHeader(),
						}).catch(function (error) {
							alert("Something went wrong." + error.message);
						});

						if (result.data.code === 'success') {
							const { data } = result.data;
							const pdfUrl = data.order_agreement.agreement;

							window.open(pdfUrl, '_blank');
							console.log('Agreement URL:', data.order_agreement.agreement);
						}
					}
				}
			}
		},
		htmlDecode(text) {
			var textArea = document.createElement('textarea');
			textArea.innerHTML = text;
			return textArea.value;
		}
	},
	watch: {}
}
</script>

<template>
	<div class="card">
		<LoadingSpinner :isLoading="isLoading" />
		<div class="card-body">
			<InputModal ref="inputModal" title="Upload Files" :preventClose="true">
				<template #inputField>
					<div class="form-group">
						<label for="input_modal">Choose type</label>
						<select class="form-control mb-2" id="input_modal" v-model="upload_action">
							<option value="" disabled>Select action</option>
							<option value="amenity" v-if="['draft', 'pending_payment', 'payment_processing', 'pending_signature'].includes(current_status)">Amenity attachments</option>
							<option value="tenant_individual_docs" v-if="['draft', 'pending_payment', 'payment_processing', 'pending_signature'].includes(current_status)">Tenant personal attachments</option>
							<option value="tenant_business_docs" v-if="['draft', 'pending_payment', 'payment_processing', 'pending_signature'].includes(current_status)">Tenant company attachments</option>
							<option value="tenant_signature" v-if="['pending_signature', 'pending_tenant_signature'].includes(current_status)">Tenant signature</option>
							<option value="landlord_individual_docs" v-if="['draft', 'pending_payment', 'payment_processing', 'pending_signature'].includes(current_status)">Landlord personal attachments</option>
							<option value="landlord_business_docs" v-if="['draft', 'pending_payment', 'payment_processing', 'pending_signature'].includes(current_status)">Landlord company attachments</option>
							<option value="landlord_signature" v-if="['pending_signature', 'pending_landlord_signature'].includes(current_status)">Landlord signature</option>
							<option value="stamp_doc" v-if="['completed', 'pending_stamping'].includes(current_status)">Tenancy stamp document</option>
						</select>

						<UploadBtn @upload="uploadFile" />
						<div class="alert alert-danger mt-3" role="alert" v-if="upload_error != ''">
							{{ upload_error }}
						</div>
					</div>
				</template>
			</InputModal>

			<InputModal ref="statusModel" title="Update Status" :preventClose="true">
				<template #inputField>
					<div class="form-group">
						<label for="order_item_status">Select a status to update:</label>
						<select class="form-control mb-2" id="order_item_status" v-model="update_status">
							<option value="" disabled>Select status</option>
							<option value="draft" v-if="current_status == 'payment_processing'">Draft</option>
							<option value="payment_processing" v-if="current_status == 'draft'">Payment Processing</option>
							<option value="pending_payment" v-if="current_status == 'draft'">Pending Payment</option>
							<!-- <option value="pending_signature">Pending Signature</option> -->
							<option value="pending_landlord_signature" v-if="['pending_signature', 'pending_landlord_signature'].includes(current_status)">Pending Landlord Signature</option>
							<option value="pending_tenant_signature" v-if="['pending_signature', 'pending_tenant_signature'].includes(current_status)">Pending Tenant Signature</option>
							<!-- <option value="pending_submit" v-if="['pending_tenant_signature', 'pending_landlord_signature'].includes(current_status)">Pending Submit</option> -->
							<option value="pending_stamping" v-if="['pending_submit'].includes(current_status)">Pending Stamping</option>
							<!-- <option value="completed">Completed</option> -->
							<option value="expired" v-if="current_status == 'completed'">Expired</option>
						</select>
						<div class="alert alert-danger mt-3" role="alert" v-if="update_status_error != ''">
							{{ update_status_error }}
						</div>
						<button class="btn btn-orange" @click="changeStatus">
							Update
						</button>
					</div>
				</template>
			</InputModal>

			<!-- <div class="row"> -->
			<!-- <div class="col-12 d-flex/ justify-content-between align-items-baseline"> -->
			<div class="d-flex justify-content-between align-items-end">
				<div>
					<h4 style="font-family: Nunito; font-size: 15px; font-weight: 700;" class="mb-0 mr-2">
						Created By: 
						<router-link class="text-primary"
							:to="{ name: 'Member View', params: { id: creator_data.id } }">
							{{ creator_data.full_name }}
						</router-link>
					</h4>
					<h4 style="font-family: Nunito; font-size: 15px; font-weight: 700;" class="mt-2 mb-0 mr-2">Tenancy ID: {{ tenancy_data.order_display_id }}</h4>
					<div class="d-flex mt-2">
						<p style="font-size: 14px; font-weight: 600;" class="mb-0 mr-2">Current Status: </p>
						<Badge :status="current_status" type="tenancy" :key="badgeKey" v-if="current_status != ''" />
					</div>
				</div>
				<div class="button_group">
					<button v-if="['draft', 'pending_payment', 'payment_processing', 'pending_signature'].includes(current_status)" class="btn btn-sm btn-success" @click="editDetails">Edit Details</button>
					<button class="btn btn-sm btn-orange ml-2" @click="$refs.statusModel.showModal()" v-if="current_status != 'pending_payment' && current_status != 'pending_stamping' && current_status != 'completed'">Update Status</button>
					<button v-if="current_status != 'pending_submit'" class="btn btn-sm btn-primary ml-2" @click="$refs.inputModal.showModal()">Upload File</button>
					<button class="btn btn-sm btn-info ml-2" @click="confirmPayment" v-if="current_status == 'payment_processing'">Confirm Payment</button>
					<button class="btn btn-sm btn-info ml-2" @click="downloadAgreement" v-if="['completed', 'pending_stamping'].includes(current_status)">Download Agreement</button>
				</div>
			</div>

			<DetailsContent :current_status="current_status" :tenancy_data="tenancy_data" :price_data="price_data" :order_totals="order_totals"
				:errors="errors" />
			<DetailsKyc :tenancy_data="tenancy_data" />

			<!-- </div> -->
		</div>
	</div>
</template>

<style scoped></style>